<template>
  <div class="wrap">
    <div class="content-wrap">
      <div class="title">联系我们</div>
      <div class="texts">Contact Us</div>
    </div>
    <div class="contact-info">
      <p>欢迎添加翎氪小助理的微信</p>
      <p>有任何问题和反馈都可以随时提出</p>
      <div class="wx">Linkr_0906</div>
    </div>
    <div class="logo-info">
      <div class="logo-info-left">
        <img class="logo-link" :src="linklogo" alt="logo">
        <div style="text-align: left; margin-left: 40px">
          <div class="txt-info">翎氪APP</div>
          <div class="link-txt">Link你关心的人和TA关心的事</div>
        </div>
      </div>
      <div class="logo-info-right">
        <img class="logo-info-right-ewm" :src="ewm" alt="二维码">
        <span>下载翎氪APP</span>
      </div>
    </div>
    <div class="footer-wrap">
      <linkFooter></linkFooter>
    </div>
  </div>
</template>

<script>
import linkFooter from "./LinkFooter";
export default {
  name: "linkHome",
  components: {
    linkFooter,
  },
  data() {
    return {
      linklogo: require("../../assets/link-logo.png"),
      // ewm: require("../../assets/link-ewm.png"),
      ewm: require("../../assets/scan-download.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  font-family: PingFangSC-Regular, PingFang SC;
  .content-wrap {
    padding-top: 240px;
    margin: 0 auto;
    .title {
      font-size: 56px;
      font-weight: 500;
      color: #ffffff;
      line-height: 64px;
    }
    .texts {
      font-size: 24px;
      color: #ffffff;
      line-height: 33px;
      margin-top: 20px;
      opacity: 0.7;
    }
  }
  .contact-info {
    font-size: 34px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 60px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    margin-top: 140px;
  }
  .content-text {
    width: 1170px;
    height: 528px;
    font-size: 34px;
    color: #FFFFFF;
    line-height: 48px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    margin: 100px auto;
    text-align: left;
  }
  .logo-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 200px auto;
    width: 1170px;
    .logo-info-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo-link {
        width: 135px;
        height: 135px;
        border: 2px solid #fff;
        border-radius: 18px;
      }
      .txt-info {
        font-size: 56px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 78px;
      }
      .link-txt {
        font-size: 30px;
        font-weight: 400;
        color: #B1B1B1;
        line-height: 42px;
      }
    }
    .logo-info-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 24px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 33px;
      }
      .logo-info-right-ewm {
        margin-right: 20px;
        width: 135px;
        height: 135px;
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .wrap .logo-info {
    width: 90%;
    margin-top: 400px;
  }
}
</style>
