<template>
  <div class="wrap">
    <div class="logo-wrap">
      <img class="img-lgoo" :src="linklogo" alt="linklogo" />
      <div class="title">翎氪APP</div>
      <div class="content">Link你关心的人和TA关心的事</div>
    </div>
    <div class="iphone-wrap">
      <div class="box">
        <img :src="iphoneImg1" alt="手机图片">
        <div class="txt">你想要关心的人</div>
      </div>
      <div class="box">
        <img :src="iphoneImg2" alt="手机图片">
        <div class="txt">TA每天关心的事</div>
      </div>
      <div class="box">
        <img :src="iphoneImg3" alt="手机图片">
        <div class="txt">行业价值信息</div>
      </div>
    </div>
    <div class="ewm-info">
      <img class="ewm" :src="ewm" alt="二维码">
      <div class="down-load-link">下载翎氪APP</div>
    </div>
    <div class="footer-wrap">
      <linkFooter></linkFooter>
    </div>
  </div>
</template>

<script>
import linkFooter from './LinkFooter';
export default {
  name: "linkHome",
  components: {
    linkFooter
  },
  data() {
    return {
      linklogo: require("../../assets/link-logo.png"),
      iphoneImg1: require("../../assets/iphone-img1.png"),
      iphoneImg2: require("../../assets/iphone-img2.png"),
      iphoneImg3: require("../../assets/iphone-img3.png"),
      // ewm: require("../../assets/link-ewm.png"),
      ewm: require("../../assets/scan-download.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  .logo-wrap {
    padding-top: 200px;
  .img-lgoo {
    width: 135px;
    height: 135px;
    border: 2px solid #fff;
    border-radius: 18px;
  }
  .title {
    font-size: 56px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 78px;
    margin: 10px auto;
  }
  .content {
    font-size: 30px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 42px;
  }
  }
  .iphone-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-top: 160px;
    .box {
      img {
        width: 263px;
        height: 568px;
        margin: 0 34px;
      }
      .txt {
        font-size: 24px;
        color: #FFFFFF;
        font-family: PingFangSC-Regular, PingFang SC;
        line-height: 33px;
        margin: 20px 0;
      }
    }
  }
  .ewm-info {
    margin: 100px auto;
    .ewm {
      width: 135px;
      height: 135px;
    }
    .down-load-link {
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #FFFFFF;
      line-height: 42px;
      margin-top: 20px;
    }
  }
  
}
</style>
