const collection = {
  namespaced: true,
  state: () => ({ 
    file:null
   }),
  mutations: { 
    setFile (state, payload) {
      // 变更状态
      state.file = payload
    },
    initFile (state) {
      state.file = null
    }
   },
  actions: { 

   },
  getters: { 

   }
}
export default collection