<template>
  <div class="layout">
    <div class="top">
      <ul>
        <li
          @click="switchTab(index)"
          v-for="(tab, index) in tabs"
          :key="index"
          :class="index === activeIndex ? 'active' : ''"
        >
          {{ tab }}
          <span :class="index === activeIndex ? 'activeBorder' : ''"></span>
        </li>
        <li @click="$router.push({name: 'index'})" class="nav-index">登录网页版</li>
      </ul>
    </div>
    <template v-if="activeIndex === 0">
      <div class="bg">
        <linkHome></linkHome>
      </div>
    </template>
    <!-- <template v-else-if="activeIndex === 1">
      <div class="bg">
        <realTimeInfo></realTimeInfo>
      </div>
    </template> -->
    <template v-else-if="activeIndex === 1">
      <div class="bg">
        <aboutUs></aboutUs>
      </div>
    </template>
    <template v-else>
      <div class="bg">
        <contactUs></contactUs>
      </div>
    </template>
  </div>
</template>

<script>

/**
 * 
 * PC开发的第二版 
 * 
 */


import linkHome from './components/LinkHome';
// import realTimeInfo from './components/RealTimeInfo';
import contactUs from './components/ContactUs';
import aboutUs from './components/AboutUs';

export default {
  name: "layout",
  components: {
    linkHome,
    // realTimeInfo,
    contactUs,
    aboutUs
  },
  data() {
    return {
      // tabs: ["首页", '资讯', "关于我们", "联系我们"],
      tabs: ["首页", "关于我们", "联系我们"],
      activeIndex: 0,
    };
  },
  created() {
    setTimeout(() => {
      this.$pageShow()
    })
  },
  methods: {
    switchTab(index) {
      this.activeIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  // width: 1440px;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  background: #000;
  overflow-y: scroll;
  position: relative;
  .top {
    width: 100%;
    min-width: 1440px;
    height: 50px;
    line-height: 50px;
    background: none;
    position: absolute;
    right: 0;
    top: 10px;
    padding-right: 135px;
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;
      // margin-left: 500px;
    }
    ul > li {
      margin-left: 75px;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      cursor: pointer;
      // width: 120px;
      position: relative;
      &.nav-index{
        // padding-left: 0;
        width: 110px;
        line-height: 30px;
        text-align: center;
        border-radius: 15px;
        border: 1px solid #FFFFFF;
      }
    }
    .active {
      color: #FED000;
    }
    .activeBorder {
      display: inline-block;
      width: 20px;
      height: 10px;
      color: #FED000;
      border-bottom: 1px solid #fed000;
      border-width: 2px;
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translate(-50%);
      padding-left: 10px;
    }
  }
  .bg {
    width: 100%;
    height: 902px;
    background: url('../assets/link-header.png') no-repeat;
    // background-size: cover;
    background-size: 100% 100%;
    background-position: center;
  }
}
</style>
