<template>
  <div>
    <div class="links">
      <ul>
        <!-- <li>用户协议<span>|</span></li>
        <li>隐私政策<span>|</span></li> -->
        <li>
          <a target="_blank" :href="url">
            京ICP备12031756号-26
          </a>
        </li>
      </ul>
    </div>
    <div class="companyName"><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502049178">
        京公网安备11010502049178号
      </a></div>
    <div class="companyName">公司名称：北京多氪信息科技有限公司</div>
    <div class="address">公司地址：北京市市辖区朝阳区酒仙桥路10号恒通国际商务园B6栋</div>
  </div>
</template>

<script>
export default {
  name: 'linkFooter',
  data() {
    return {
      url: 'https://beian.miit.gov.cn/#/Integrated/recordQuery'
    }
  }
};
</script>

<style scoped lang="scss">
.links {
  padding-top: 20px;
  margin: 0 auto;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    li {
      float: left;
      font-size: 22px;
      color: #ffffff;
      line-height: 32px;
      span {
        display: inline-block;
        margin: 10px 15px 5px 15px;
      }
      a {
        color: #FFFFFF;
      }
    }
  }
}
.companyName, .address, .companyName a {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 32px;
  margin-top: 10px;
}
</style>
