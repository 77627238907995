import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../views/Layout.vue";
// import store from '../store/index.js'
import { refreshToken } from "../api/pc";
// import { refreshToken } from '../api/pc'
import NProgress from "nprogress";
import "nprogress/nprogress.css";
Vue.use(VueRouter);

NProgress.configure({
  easing: "ease", // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
});
let isRefresh = false;

const routes = [
  {
    path: "/",
    name: "Home",
    component: Layout,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/Index.vue"),
  },
  {
    path: "/loginmiddle",
    name: "loginmiddle",
    component: () => import("../views/login/Loginmiddle.vue"),
  },
  {
    path: "/bindphone",
    name: "bindphone",
    component: () => import("../views/login/Bindphone.vue"),
  },
  {
    path: "/inviteInfo",
    name: "inviteInfo",
    component: () => import("../views/login/InviteInfo.vue"),
  },
  {
    path: "/bindaccount",
    name: "bindaccount",
    component: () => import("../views/login/BindAccount.vue"),
  },
  {
    path: "/publish",
    name: "publish",
    component: () => import("../views/publish/Index.vue"),
  },
  {
    path: "/linkrArticleEditor",
    name: "linkrArticleEditor",
    component: () => import("../views/linkrArticleEditor/Index.vue"),
  },
  {
    path: "/answerQuestion",
    name: "answerQuestion",
    component: () => import("../views/answerQuestion/Index.vue"),
  },
  {
    path: "/publishQuestion",
    name: "publishQuestion",
    component: () => import("../views/publishQuestion/Index.vue"),
  },
  {
    path: "/reviewLinkrArticle",
    name: "预览",
    component: () => import("../views/reviewLinkrArticle/Index.vue"),
  },
  {
    path: "/addCollection",
    name: "addCollection",
    component: () => import("../views/addCollection/Index.vue"),
  },
  {
    path: "/index",
    name: "index",
    redirect: { name: "indexPage" },
    component: () => import("../views/index/index.vue"),
    children: [
      {
        path: "marketDetail/:collectionId",
        name: "marketDetail",
        redirect: { name: "KrDetail" },
        component: () => import("../views/marketDetail/index.vue"),
        children: [
          {
            path: "KrDetail",
            name: "KrDetail",
            component: () => import("../views/KrDetail/Index.vue"),
          },
          {
            path: "KrQuestions",
            name: "KrQuestions",
            component: () => import("../views/KrQuestions/Index.vue"),
          },
        ],
      },
      {
        path: "myCollection",
        name: "myCollection",
        component: () => import("../views/myCollection/Index.vue"),
      },
      // {
      //   path: 'collection',
      //   name: 'collection',
      //   redirect:{name:'myCollection'},
      //   component: () => import('../views/collection/Index.vue'),
      //   children:[

      //   ]
      // },
      {
        path: "mine/:userId",
        name: "mine",
        component: () => import("../views/mine/index.vue"),
        props: true,
        redirect: { name: "myDynamic" },
        children: [
          {
            path: "myDynamic",
            name: "myDynamic",
            component: () => import("../views/mine/components/myDynamic.vue"),
          },
          {
            path: "keMarket",
            name: "keMarket",
            component: () => import("../views/mine/components/keMarket.vue"),
          },
        ],
      },
      {
        path: "indexPage",
        name: "indexPage",
        redirect: { name: "link" },
        component: () => import("../views/index/indexPage.vue"),
        children: [
          {
            path: "find",
            name: "find",
            component: () => import("../views/find/index.vue"),
            meta: {
              keepAlive: true,
            },
          },
          {
            path: "link",
            name: "link",
            component: () => import("../views/link/index.vue"),
            meta: {
              keepAlive: true,
            },
          },
          {
            path: "search",
            name: "search",
            component: () => import("../views/search/index.vue"),
          },
          // {
          //   path:'Recommendation',
          //   name:'Recommendation',
          //   component:()=> import('../views/Recommendation/index.vue'),
          // }
          {
            path: "concentrate",
            name: "concentrate",
            component: () => import("../views/concentrate/index.vue"),
          },
        ],
      },
      {
        path: "linkrDetail",
        name: "linkrDetail",
        component: () => import("../views/linkrDetail/Index.vue"),
      },
    ],
    beforeEnter: (to, form, next) => {
      let refreshTokeninfo = localStorage.getItem("refreshtoken");
      let lastTime = localStorage.getItem("tokenTime");
      let newTime = new Date();
      let res = new Date(lastTime - 0);
      res.setHours(0);
      res.setMinutes(0);
      res.setSeconds(0);
      newTime.setHours(0);
      newTime.setMinutes(0);
      newTime.setSeconds(0);
      let resTime = res.getTime();
      console.log(newTime, res, newTime > res);
      if (lastTime && newTime > resTime) {
        isRefresh = true;
      } else {
        isRefresh = false;
      }
      if (isRefresh) {
        if (refreshTokeninfo) {
          refreshToken({}, { refreshToken: refreshTokeninfo }).then((res) => {
            switch (res.code) {
              case 200:
                isRefresh = false;
                // localStorage.setItem("isRefreshed",true)
                next();
                break;
              default:
                isRefresh = true;
                next({ name: "login" });
                break;
            }
          });
        } else {
          isRefresh = true;
          next({ name: "login" });
        }
      } else if (!refreshTokeninfo || !lastTime) {
        next({ name: "login" });
      } else {
        next();
      }
    },
  },
  {
    path: "*",
    redirect: { name: "Home" },
  },
];
// 解决上面跳转加参数报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

// 修改路由replace方法,阻止重复点击报错
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  base: "/",
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (to.meta.keepAlive && savedPosition) {
      return { x: 0, y: to.meta.scrollTop };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // 每次切换页面时，调用进度条
  NProgress.start();
  // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
  window.pageHide();

  // 判断是否keepAlive

  next();
});
// 当路由进入后：关闭进度条
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done();
});

export default router;
