import api from "./request";

// 消费流分享详情
export const login = (params) =>
  api.post("/zero/open/user/api/login", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });

// 获取短信验证码接口
export const getCode = (params) =>
  api.post("/zero/open/user/api/code/get", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 账号绑定
// export const bindWechat = (params) => api.post('/zero/open/user/api/bind', params, { 'Content-Type': 'application/x-www-form-urlencoded' })
// 验证手机号
export const checkPhone = (params) =>
  api.post("/zero/open/user/api/phone/check", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 邀请码绑定
export const bindAccount = (params) =>
  api.post("/zero/open/user/api/inviteCode/bind", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 行业地区等信息获取
export const commonGet = (params) =>
  api.post("/zero/open/config/api/common/get", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 创建账号
export const create = (params) =>
  api.post("/zero/wukong/user/api/create", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// feed点赞
export const praise = (params) =>
  api.post("/zero/wukong/consumer/api/feed/praise", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// feed点赞取消
export const unPraise = (params) =>
  api.post("/zero/wukong/consumer/api/feed/unPraise", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 获取微信登录地址
export const weixinLogin = (params) =>
  api.get("/zero/open/user/api/weixin/pc/redirect", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 根据url获取文章信息
export const getByUrl = (params) =>
  api.post("/zero/wukong/producer/api/article/getByUrl", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 增加动态
export const addLinkr = (params) =>
  api.post("/zero/wukong/producer/api/linkr/add", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });

// 获取大咖驾到模块
export const getKolList = (params) =>
  api.get("/zero/wukong/producer/api/kol/list", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });

// feed流中未购买的
export const getUnBuylist = (params) =>
  api.get("/zero/wukong/consumer/api/feed/pay/getUnBuylist", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
//请求feed流列表
export const getFeedStream = (params) =>
  api.get("/zero/wukong/consumer/api/feed/list", params);
// 氪集详情页
export const getFeedDetail = (params) =>
  api.get("/zero/wukong/consumer/api/feed/detail", params);
// 获取推荐文章列表
export const getArticleList = (params) =>
  api.get("/zero/wukong/producer/api/article/list", params);
// 获取评论列表
// export const getCommentList = (params) => api.post('/zero/open/comment/api/query/list',params, { 'Content-Type': 'application/x-www-form-urlencoded' })
export const getCommentList = (params) =>
  api.post("/zero/wukong/comment/api/query/list/page", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });

// 增加评论
export const addComment = (params) =>
  api.post("/zero/wukong/comment/api/add", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 点赞评论
export const commentPraise = (params) =>
  api.post("/zero/wukong/comment/api/praise", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 取消评论点赞
export const commentUnPraise = (params) =>
  api.post("/zero/wukong/comment/api/unPraise", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 发现页获取筛选者列表
export const getDiscoverList = (params) =>
  api.get("/zero/wukong/producer/api/discover/list", params);
//获取用户配置信息
export const getAppConf = (param) =>
  api.get("/zero/wukong/config/api/app/get", param);
//获取用户信息
export const getUserInfo = (params) =>
  api.post("/zero/wukong/user/api/self/info/get", params, {
    "Content-Type": "application/json",
  });
//获取筛选者-动态列表
export const getDynamic = (params) =>
  api.get("/zero/wukong/producer/api/feed/list", params);
//获取筛选着详情
export const getSifterInfo = (params) =>
  api.get("/zero/wukong/producer/api/detail", params);
//link筛选者
export const setLink = (params) =>
  api.post("/zero/wukong/consumer/api/producer/link", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
//取消link
export const unLink = (params) =>
  api.post("/zero/wukong/consumer/api/producer/unLink", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
//获取氪集列表
export const getMarketList = (params) =>
  api.get("/zero/wukong/collection/api/query", params);
// 获取氪集列表
export const getSelfMarketList = (params) =>
  api.get("/zero/wukong/collection/api/querySimplList", params);
//获取氪集详情
export const getMarketDetail = (params) =>
  api.get("/zero/wukong/collection/api/detail", params);
//获取氪集-动态列表
export const getMarketDynamic = (params) =>
  api.get("/zero/wukong/collection/api/feed/list", params);
//刷新token
export const refreshToken = (params, headers) =>
  api.get("/zero/open/user/api/refresh", params, headers);
//获取氪集详情页标签列表
export const getLabelList = (param) =>
  api.post("/zero/wukong/collection/api/tag/list", param, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 搜索筛选者
export const getProducer = (param) =>
  api.post("/zero/wukong/s/api/producer/query", param, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 搜索氪集
export const getCollection = (param) =>
  api.post("/zero/wukong/s/api/collection/query", param, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 搜索文章
export const searchArticle = (param) =>
  api.post("/zero/wukong/s/api/article/query", param, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
//查询收藏标签列表
export const getCollectionTags = (param) =>
  api.post("/zero/wukong/favorites/api/tag/list", param, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
//提交收藏
export const addCollection = (param) =>
  api.post("/zero/wukong/favorites/api/addImageText", param, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
//收藏移入标签
export const removeCollectionTags = (param) =>
  api.post("/zero/wukong/favorites/api/tag/in", param, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 收藏列表
export const favoriteList = (param) =>
  api.post("/zero/wukong/favorites/api/list", param, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
//已link列表
export const linkedList = (param) =>
  api.get("/zero/wukong/user/api/link/list", param);
//搜索用户
export const searchUser = (param) =>
  api.post("/zero/wukong/s/api/user/query", param, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
//图片上传
export const imgUpload = (param) =>
  api.post("/zero/open/file/api/img/upload", param, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
//氪集-问答列表
export const answerList = (params) =>
  api.post("/zero/wukong/collection/api/feed/answer/list", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
//增加回答
export const addAnswer = (param) =>
  api.post("/zero/wukong/collection/api/answer/add", param, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
//提问者提问前前检查
export const answerCheck = (params) =>
  api.get("/zero/wukong/collection/api/answer/check", params);

//获取emoji表情顺序
export const emojiList = (params) =>
  api.post("/zero/wukong/config/api/resource/emoticon/order/list", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 搜索动态
export const searchLinkr = (params) =>
  api.post("/zero/wukong/s/api/linkr/query", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// oss临时上传凭证
export const getOssUploadSts = (params) =>
  api.post("/zero/wukong/favorites/api/file/sts/get", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 获取文件访问授权
export const getOssFileView = (params) =>
  api.get("/zero/wukong/favorites/api/file/get", params);
// 获取文件访问地址
export const getFileAccessUrl = (params) =>
  api.post("/zero/wukong/producer/api/file/get", params, {
    "Content-Type": "application/x-www-form-urlencoded",
  });
// 获取精选栏目列表
export const getColumnList = (params) =>
  api.get("/zero/wukong/article/api/column/list", params);
// 获取精选栏目下模块列表
export const getModuleList = (params) =>
  api.get("/zero/wukong/article/api/column/module/list", params);
