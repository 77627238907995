import Vue from 'vue'
import Vuex from 'vuex'
import {getUserInfo, getAppConf} from '@/api/pc'
import collection from './modules/collection'
Vue.use(Vuex)
let userInfo = localStorage.getItem('USERINFO')
let appConfig = localStorage.getItem('appConfig')
export default new Vuex.Store({
  state: {
    userInfo: userInfo && JSON.parse(userInfo),
    appConfig: appConfig && JSON.parse(appConfig)
  },
  getters: {
    invitcount: state => {
      return parseInt(state.userInfo?.invitedCount) || 0
    }
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    initUserinfo (state) {
      state.userInfo = null;
    },
    setAppConfigCommit(state, appConfig) {
      state.appConfig = appConfig
    }
  },
  actions: {
    setUserInfo({commit}) {
      getUserInfo().then(res => {
        localStorage.setItem('USERINFO', JSON.stringify(res.data.userInfo))
        commit('setUserInfo', res.data.userInfo)
        window.sensors && window.sensors.registerPage({
          account: res.data.userInfo.userId,
        });
      })
    },
    setAppConfig ({commit}) {
      return getAppConf().then(res => {
        localStorage.setItem('appConfig',JSON.stringify(res.data))
        commit('setAppConfigCommit',res.data)
        return res
      })
    }
  },
  modules: {
    collection,
  }
})
