<template>
  <div class="wrap">
    <div class="content-wrap">
      <div class="title">来自创始团队的一封信</div>
      <div class="texts">A letter from the founding team</div>
    </div>
    <div class="content-text">
      <p class="p1">Hi 朋友，</p><br>
      <p class="p1">你是否会在每日繁多的各类资讯文章中感到迷茫，不知如何辨别筛选高质量内容，阅读也因此逐渐变得毫无营养？你是否会被各类平台的“流量密码”束缚，原创优质的观点和内容无人问津，创作也因此逐渐变得索然无味？</p><br>
      <p class="p1">当有一群各行业达人，正在甄选、整理并分享有价值的内容，你是否愿意与他们建立深层次的交流？当有一群读者，渴望有一个干净高效的氛围，能够不被无意义的广告推送打扰，专注享受阅读的乐趣，你是否愿意与我们共建这样的“理想社区”？</p><br>
      <p class="p1">为了实现这份期待，为了让优质的信息与观点相互碰撞、蓬勃生长，翎氪应运而生。<strong class="default-active">翎氪取意于英文单词「Link - 连接」，是一个专注高价值内容推荐与分享的社区平台。</strong></p><br>
      <p class="p1">我们希望通过最简单的方式，将你与专业的人相连， 在分享与探讨中将思想的力量传递给彼此。社区中每个优质的观点，都是一抹闪烁的星光，点点繁星汇聚成璀璨，便能让我们更看清一些前路的方向。</p><br>
      <p class="p1">在翎氪内你可以：</p>
      <p class="p1">作为普通用户，连接行业达人。发现你认可的人，选择建立Link关系，持续关注达人挑选的内容与观点，收获价值。</p><br>
      <p class="p1">作为行业达人，连接读者。在你擅长的领域内，持续筛选并输出原创高质量观点，认真严谨，对Link你的每一位读者负责。</p><br>
      <p class="p1">无论你是谁，都可以在这里畅快表达所思所想。因为你的每一次评论和点赞，都是对达人及平台的友善回应，舒适的氛围需要你我一同共建维护。</p><br>
      <p class="p1"><strong class="default-active">通过翎氪，连接起你关心的人和TA关心的事。</strong>只要能让你在这里感受到一点点力量，我们都深感荣幸。</p><br>
      <p class="p1">你好朋友，欢迎来到翎氪，现在开始Link你关心的人吧。</p><br>
      <p>-  来自 翎氪APP团队</p>
    </div>
    <div class="logo-info">
      <div class="logo-info-left">
        <img class="logo-link" :src="linklogo" alt="logo">
        <div style="text-align: left; margin-left: 40px">
          <div class="txt-info">翎氪APP</div>
          <div class="link-txt">Link你关心的人和TA关心的事</div>
        </div>
      </div>
      <div class="logo-info-right">
        <img class="logo-info-right-ewm" :src="ewm" alt="二维码">
        <span>下载翎氪APP</span>
      </div>
    </div>
    <div class="footer-wrap">
      <linkFooter></linkFooter>
    </div>
  </div>
</template>

<script>
import linkFooter from "./LinkFooter";
export default {
  name: "linkHome",
  components: {
    linkFooter,
  },
  data() {
    return {
      linklogo: require("../../assets/link-logo.png"),
      // ewm: require("../../assets/link-ewm.png"),
      ewm: require("../../assets/scan-download.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  .content-wrap {
    padding-top: 280px;
    margin: 0 auto;
    font-family: PingFangSC-Medium, PingFang SC;
    .title {
      font-size: 56px;
      font-weight: 500;
      color: #ffffff;
      line-height: 64px;
    }
    .texts {
      font-size: 24px;
      color: #ffffff;
      line-height: 33px;
      margin-top: 20px;
      opacity: 0.7;
    }
  }
  .content-text {
    width: 1170px;
    // height: 528px;
    font-size: 34px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #FFFFFF;
    line-height: 48px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    margin: 100px auto;
    text-align: left;
    padding-top: 100px;
    .default-active {
        color: #FED000;
    }
    p:last-child {
        text-align: right;
    }
  }
  .logo-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 220px auto;
    width: 1170px;
    .logo-info-left {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo-link {
        width: 135px;
        height: 135px;
        border: 2px solid #fff;
        border-radius: 18px;
      }
      .txt-info {
        font-size: 56px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 78px;
      }
      .link-txt {
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #B1B1B1;
        line-height: 42px;
      }
    }
    .logo-info-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 33px;
      }
      .logo-info-right-ewm {
        margin-right: 20px;
        width: 135px;
        height: 135px;
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .wrap .content-text{
    width: 90%;
  }
  .wrap .logo-info {
    width: 90%;
    margin-top: 400px;
  }
}
</style>
