import Vue from "vue";
import App from "./App.vue";
// import './registerServiceWorker'
import router from "./router";
import "normalize.css";
import store from "./store";
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import "./styles/global.css";
import "@/assets/font/font.css";

Vue.config.productionTip =
  process.env.VUE_APP_ENV === "development" ? true : false;
Vue.config.devtools = process.env.VUE_APP_ENV === "development" ? true : false;

// Vue.use(ElementUI, { size: 'small' });

Vue.config.productionTip = false;
Vue.prototype.$pageShow = () => {
  document.getElementById("app").style.display = "block";
};
Vue.prototype.$pageHide = window.pageHide = () => {
  document.getElementById("app").style.display = "none";
};

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
}).$mount("#app");
