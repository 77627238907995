<template>
  <div id="app" class="app-box">
    <router-view />
    <!-- <img class="test" src="./查看更多.png" alt=""> -->
  </div> 
</template>

<script>
export default {
  computed: {
    key () {
      return this.$route.name ? this.$route.name + +new Date() : this.$route + +new Date()
    }
  },
}
</script>

<style>
.app-box{
  display: none;
}
.test{
  position: fixed;
  top: -210px;
  left: 50%;
  transform: translateX(-50%);
  width: 1440px;
  background: forestgreen;
  opacity: 0.6;
  display: none;
}
</style>
