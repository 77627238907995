
// const config = {
//     development: {
//         baseURL: '/gateway/'
//     },
//     test: {
//         baseURL: '/gateway/',
//     },
//     testing: {
//         baseURL: '/gateway/',
//     },
//     production: { 
//         baseURL: '/gateway/', 
//     }
// }
export default (key) => {
    // return config[process.env.VUE_APP_ENV][key] 
    return {
        baseURL: '/gateway/', 
    }[key]
}